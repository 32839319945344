import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import CTA from "../components/CTA";
import OurClients from "../components/OurClients";
import SwiperComponent from "../components/ProductVisualsSwiper";
import ScrollToTopButton from "../components/ScrollTop";
import OurProducts from "../components/OurProducts";
import { Link } from "react-router-dom";
import SEO from "../components/SEO";
const slidesData = [
  {
    image: "/assets/images/home/products-visuals-corporate.jpg",
    title: "products-visuals-corporate",
    description:
      "Elevate collaboration and engagement with our interactive displays, transforming meetings and classrooms into dynamic environments.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionBottom: "150px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/products-visuals-tab.jpg",
    title: "products-visuals-tab",
    description:
      "Empower productivity on the go with our tablets, offering seamless performance for work, study, and creativity.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionTop: "150px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/products-visuals-room-scheduler.jpg",
    title: "products-visuals-room-scheduler",
    description:
      "Optimize space management and boost efficiency with our intuitive room scheduler, simplifying meeting planning and coordination.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionBottom: "120px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/products-visuals-kiosk.jpg",
    title: "products-visuals-kiosk",
    description:
      "Enhance customer interaction and streamline services with our user-friendly kiosks, designed for efficiency and engagement.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionTop: "150px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/products-visuals-video-wall.jpg",
    title: "products-visuals-video-wall",
    description:
      "Create impactful visual experiences with our video walls, delivering stunning clarity and seamless scalability for any space.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionBottom: "120px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/multi-panel-wall-display-featuring.png",
    title: "multi-panel-wall-display-featuring",
    description:
      "Elevate collaboration and engagement with our interactive displays, transforming meetings and classrooms into dynamic environments.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionBottom: "100px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
  {
    image: "/assets/images/home/products-visuals-desktop.jpg",
    title: "products-visuals-desktop",
    description:
      "Streamline your workflow with our all-in-one touch monitors, combining powerful performance with intuitive, touch-enabled interfaces.",
    buttonText: "Learn More",
    buttonLink: "#",
    contentPositionBottom: "120px", // Example of dynamic position
    contentPositionLeft: "50px",
  },
];
const features = [
  {
    title: "Innovation",
    description:
      "We tirelessly innovate to solve customer problems, pushing boundaries to achieve the impossible.",
    image: "/assets/images/home/innovation.svg",
    altTag: "Innovation",
  },
  {
    title: "Precision",
    description:
      "We leave no room for error. Whatever we do, our Engineers aim nothing but Perfection.",
    image: "/assets/images/home/precision.svg",
    altTag: "Precision",
  },
  {
    title: "Durability",
    description:
      "Our focus on perfection sets very high standards in design, production, marketing, and support.",
    image: "/assets/images/home/durability.svg",
    altTag: "Durability",
  },
  {
    title: "Intuitive",
    description:
      "We Admire simplicity & we have imbibed the same in our Products. Hence, Easy to Use.",
    image: "/assets/images/home/intuitive.svg",
    altTag: "Intuitive",
  },
];

const Home = () => {
  const [educationImage, setEducationImage] = useState(
    "/assets/images/home/solutions-education-home-half.jpg"
  );
  const [corporateImage, setCorporateImage] = useState(
    "/assets/images/home/products-corporate-half.jpg"
  );
  const [isEducationHovered, setIsEducationHovered] = useState(false);
  const [isCorporateHovered, setIsCorporateHovered] = useState(false);

  const handleMouseEnterEducation = () => {
    setEducationImage("/assets/images/home/solutions-education-home-full.jpg");
    setIsEducationHovered(true); // Set the hover state to true
  };

  const handleMouseLeaveEducation = () => {
    setEducationImage("/assets/images/home/solutions-education-home-half.jpg");
    setIsEducationHovered(false); // Revert the hover state to false
  };

  const handleMouseEnterCorporate = () => {
    setCorporateImage("/assets/images/home/products-corporate-full.jpg");
    setIsCorporateHovered(true); // Set the hover state to true
  };

  const handleMouseLeaveCorporate = () => {
    setCorporateImage("/assets/images/home/products-corporate-half.jpg");
    setIsCorporateHovered(false); // Revert the hover state to false
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalRef = useRef(null);
  useEffect(() => {
    startInterval();

    return () => clearInterval(intervalRef.current);
  }, []);

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setActiveIndex(() => Math.floor(Math.random() * features.length));
    }, 3000);
  };

  const stopInterval = () => {
    clearInterval(intervalRef.current);
  };

  const handleMouseEnter = (index) => {
    stopInterval();
    setActiveIndex(index); // Set activeIndex to the hovered card's index
  };

  const handleMouseLeave = () => {
    startInterval();
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <SEO
        title="Coltec Global - Interactive Display & Collaboration Solutions for Education Industry"
        description="Coltec Global Provides Pioneering Collaboration | Conferencing & Smart Workspace Solutions for Corporate & Education Markets."
        keywords="Collaborate Beyond Technology, Pioneering Collaboration, Solutions for Corporate, Education Markets"
        ogTitle="Coltec Global - Interactive Display & Collaboration Solutions for Education Industry"
        ogDescription="Coltec Global Provides Pioneering Collaboration | Conferencing & Smart Workspace Solutions for Corporate & Education Markets."
        ogImage="/assets/images/coltec-logo-black.svg"
        ogUrl="https://coltec-global.com/"
        canonicalUrl="https://coltec-global.com/"
      />
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="/assets/images/home/banner-img.jpg"
          altText="Background image"
          headerText="Collaborate Beyond Technology"
          bannerHeight="md:h-dvh h-[60vh]"
          positionClasses="absolute md:bottom-20 bottom-20 left-0 lg:left-0 lg:right-0 text-center"
          headerTextClasses="md:text-[44px] text-[29px] font-semibold not-italic text-white leading-2 md:leading-[48px]"
          paragraphTextClasses="md:text-[21px] text-base not-italic font-normal md:leading-[30px] mt-4 text-white max-w-[664px]"
          paragraphText="Pioneering Collaboration | Conferencing & Smart Workspace Solutions for Corporate & Education Markets"
        />
      </div>
      {/* 
          Our Solutions section start here
        */}
      <div className="bg-gray-100">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto">
          <div className="lg:text-center md:mb-10 mb-5">
            <h2 className="md:text-[40px] text-[28px] font-normal leading-8">
              Our Solutions
            </h2>
          </div>

          {/* Desktop View */}
          {isDesktop && (
            <div className="grid grid-cols-12 gap-12">
              <div className="md:col-span-2 col-span-full -mt-2">
                <Link to="/solutions/education">
                  <p className="text-[28px] text-[#424242] hover:text-gray-950 font-medium">
                    Education
                  </p>
                </Link>
                <hr className="w-28 my-2 border-gray-300" />
                <Link to="/solutions/corporate">
                  <p className="text-[28px] text-[#424242] hover:text-gray-950 font-medium">
                    Corporate
                  </p>
                </Link>
              </div>
              <div className="md:col-span-10 col-span-full relative">
                <div className="w-full lg:h-[500px] flex lg:flex-row flex-col space-y-6 lg:space-y-0">
                  <div
                    className={`w-full relative cursor-pointer overflow-hidden hover-img-div lg:rounded-l-lg ${
                      isEducationHovered ? "w-full" : "lg:w-[540px] w-full"
                    }`}
                    onMouseEnter={handleMouseEnterEducation}
                    onMouseLeave={handleMouseLeaveEducation}
                  >
                    <img
                      alt="solutions-education-home-half"
                      src={educationImage}
                      className={`h-[500px] image lg:rounded-l-lg flex-shrink-0 ${
                        isEducationHovered ? "w-full" : "lg:w-[540px] w-full"
                      }`}
                    />
                    <div className="content-layer">
                      <div className="absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
                        <p className="text-white max-w-xl text-base mb-3">
                          Engineered to revolutionize traditional teaching,
                          creating dynamic and captivating learning experiences.
                        </p>
                        <Link
                          className="bg-[#0A78B9] hover:bg-[#0a79b9f7] text-white px-6 py-2 rounded-md inline-flex items-center"
                          to="/solutions/education"
                        >
                          Learn more
                          <img
                            alt="right-arrow"
                            src="/assets/images/right-arrow.svg"
                            className="h-2 pl-2"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`w-full relative cursor-pointer overflow-hidden hover-img-div lg:rounded-r-lg ${
                      isCorporateHovered ? "w-full" : "lg:w-[540px] w-full"
                    }`}
                    onMouseEnter={handleMouseEnterCorporate}
                    onMouseLeave={handleMouseLeaveCorporate}
                  >
                    <img
                      alt="products-corporate-half"
                      src={corporateImage}
                      className={`h-[500px] image lg:rounded-r-lg flex-shrink-0 ${
                        isCorporateHovered ? "w-full" : "lg:w-[540px] w-full"
                      }`}
                    />
                    <div className="content-layer">
                      <div className="absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
                        <p className="text-white max-w-xl text-base mb-3">
                          We provide intuitive, top-tier collaboration &
                          conferencing solutions tailored for every space,
                          ensuring seamless communication across all room sizes.
                        </p>
                        <Link
                          className="bg-[#0A78B9] hover:bg-[#0a79b9f7] text-white px-6 py-2 rounded-md inline-flex items-center"
                          to="/solutions/corporate"
                        >
                          Learn more
                          <img
                            alt="right-arrow"
                            src="/assets/images/right-arrow.svg"
                            className="h-2 pl-2"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Tablet and Mobile View */}
          {!isDesktop && (
            <div className="grid grid-cols-12 gap-y-8">
              <div className="md:col-span-2 col-span-full mt-3">
                <Link to="/solutions/education">
                  <p className="md:text-[28px] text-[20px] text-[#424242] hover:text-gray-950 font-medium">
                    Education
                  </p>
                </Link>
                <hr className="w-28 my-2 border-gray-300" />
                <Link to="/solutions/corporate">
                  <p className="md:text-[28px] text-[20px] text-[#424242] hover:text-gray-950 font-medium">
                    Corporate
                  </p>
                </Link>
              </div>
              <div className="md:col-span-10 col-span-full">
                <div className="w-full relative cursor-pointer overflow-hidden hover-img-div rounded-lg">
                  <img
                    alt="solutions-education-home-full"
                    src="/assets/images/home/solutions-education-home-full.jpg"
                    className="object-contain image rounded-lg w-full"
                  />
                  <div className="content-layer">
                    <div className="absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
                      <p className="text-white max-w-xl lg:text-base text-sm mb-3">
                        Engineered to revolutionize traditional teaching,
                        creating dynamic and captivating learning experiences.
                      </p>
                      <Link
                        className="bg-[#0A78B9] hover:bg-[#0a79b9f7] text-white px-6 py-2 rounded-md inline-flex items-center"
                        to="/solutions/education"
                      >
                        Learn more
                        <img
                          alt="right-arrow"
                          src="/assets/images/right-arrow.svg"
                          className="h-2 pl-2"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="w-full relative cursor-pointer overflow-hidden hover-img-div rounded-lg mt-6">
                  <img
                    alt="products-corporate-full"
                    src="/assets/images/home/products-corporate-full.jpg"
                    className="object-contain image rounded-lg w-full"
                  />
                  <div className="content-layer">
                    <div className="absolute bottom-0 right-0 left-0 bg-gradient-radial bg-gray-950/50 backdrop-blur-md border-t border-t-white/10 rounded-b-lg p-5">
                      <p className="text-white max-w-xl lg:text-base text-sm mb-3">
                        We provide intuitive, top-tier collaboration &
                        conferencing solutions tailored for every space,
                        ensuring seamless communication across all room sizes.
                      </p>
                      <Link
                        className="bg-[#0A78B9] hover:bg-[#0a79b9f7] text-white px-6 py-2 rounded-md inline-flex items-center"
                        to="/solutions/corporate"
                      >
                        Learn more
                        <img
                          alt="right-arrow"
                          src="/assets/images/right-arrow.svg"
                          className="h-2 pl-2"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* 
          Our Solutions section ends here
        */}
      <div>
        <OurProducts />
      </div>
      <div>
        {/* 
          Products overview swiper section start here
        */}
        <div className="relative isolate overflow-hidden">
          <div className="absolute bg-gray-950/40 left-0 right-0 h-full"></div>
          <SwiperComponent slidesData={slidesData} />
        </div>
        {/* 
          Products overview swiper section ends here
        */}
      </div>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl m-auto relative overflow-hidden">
        <div className="max-w-2xl mx-auto lg:text-center">
          <h3 className="md:text-[40px] text-[28px] leading-9 font-normal lg:text-center mb-8">
            Our Philosophy
          </h3>
          <p className="text-gray-700 text-base mb-8">
            COLTEC Global's in-house innovation lab drives cutting-edge designs
            and high-functionality products. Thanks to our creative engineers
            and SMEs, we've delivered top-tier solutions that enhance
            collaboration and productivity.
          </p>
        </div>
        <div>
          <ul className="relative z-[2] mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-4 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 md:max-w-none lg:grid-cols-3 2xl:grid-cols-4">
            {features.map((feature, index) => (
              <li
                key={index}
                className={`group features rounded-lg border border-gray-200 p-6 transition-all ease-linear duration-500 hover:shadow-lg hover:-translate-y-2 ${
                  index === activeIndex ? "bg-sky-400" : "bg-white"
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  key={index}
                  className={`flex-shrink-0 w-10 h-10 rounded-full ${
                    index === activeIndex ? "bg-white" : "bg-gray-200"
                  }`}
                >
                  <div className="flex justify-center items-center p-1.5">
                    <img
                      alt={feature.altTag}
                      src={feature.image}
                      width={30}
                      height={30}
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <h3
                    key={index}
                    className={`text-lg mb-2 font-medium ${
                      index === activeIndex ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {feature.title}
                  </h3>
                  <p
                    key={index}
                    className={`text-sm ${
                      index === activeIndex ? "text-white" : "text-gray-600"
                    }`}
                  >
                    {feature.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="absolute left-1/2 top-4 h-[630px] w-full -translate-x-1/2 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:top-28 lg:ml-12 xl:top-[7.5rem] xl:ml-0">
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className="absolute inset-0 h-full w-full animate-spin-slow"
          >
            <path
              d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
              stroke="#D4D4D4"
              strokeOpacity="0.9"
            ></path>
            <path
              d="M513 1025C230.23 1025 1 795.77 1 513"
              stroke="url(#:S1:-gradient-1)"
              strokeLinecap="round"
            ></path>
            <defs>
              <linearGradient
                id=":S1:-gradient-1"
                x1="1"
                y1="513"
                x2="1"
                y2="1025"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            aria-hidden="true"
            className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
          >
            <path
              d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
              stroke="#D4D4D4"
              strokeOpacity="0.9"
            ></path>
            <path
              d="M913 513c0 220.914-179.086 400-400 400"
              stroke="url(#:S1:-gradient-2)"
              strokeLinecap="round"
            ></path>
            <defs>
              <linearGradient
                id=":S1:-gradient-2"
                x1="913"
                y1="513"
                x2="913"
                y2="913"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#06b6d4"></stop>
                <stop offset="1" stopColor="#06b6d4" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="bg-gray-100">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Home;
