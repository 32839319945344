import React from "react";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollTop";
import ContactForm from "../components/ContactForm";
import SEO from "../components/SEO";
const Connect = () => {
  return (
    <>
      <SEO
        title="Connect - Discover Customized Solutions & Partnerships | Coltec Global"
        description="Connect with COLTEC experts and get the best solution tailored to your unique requirements."
        keywords="Connect, Contact Us, Design Office, Manufacturing and Regional Office, Contact Sales, Innovative Solutions"
        ogTitle="Connect - Discover Customized Solutions & Partnerships | Coltec Global"
        ogDescription="Connect with COLTEC experts and get the best solution tailored to your unique requirements."
        ogImage="/assets/images/connect-banner-img.jpg"
        ogUrl="https://coltec-global.com/connect"
        canonicalUrl="https://coltec-global.com/connect"
      />
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="/assets/images/connect-banner-img.jpg"
          altText="connect-banner-img"
          headerText="CONTACT US"
          bannerHeight="md:h-dvh h-[50vh]"
          opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
          paragraphText="Connect with COLTEC experts and get the best solution tailored to your unique requirements."
          positionClasses="absolute md:bottom-64 bottom-20 left-0 lg:left-32"
          headerTextClasses="text-3xl md:text-4xl max-w-xl text-white font-medium leading-10"
          paragraphTextClasses="text-base text-white max-w-lg mt-4"
        />
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <h3 className="md:text-[40px] text-[28px] font-normal lg:text-center md:mb-8 mb-5">
            Locations
          </h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
            <div className="rounded-lg bg-gray-50 p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Design Office
              </h3>
              <address className="mt-3 space-y-2 text-sm not-italic leading-6 text-gray-600">
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="/assets/images/aboutus/location-icon.svg"
                    className="h-4 mr-2"
                  />
                  1014 - 155 Hillcrest Avenue, Mississauga, Canada ON L5B 3Z2
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="/assets/images/aboutus/call-icon.svg"
                    className="h-4 mr-1.5"
                  />
                  +1 (647) 564-6779
                </p>
              </address>
            </div>
            <div className="rounded-lg bg-gray-50 p-6">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Manufacturing and Regional Office
              </h3>
              <address className="mt-3 space-y-2 text-sm not-italic leading-6 text-gray-600">
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="/assets/images/aboutus/location-icon.svg"
                    className="h-4 mr-2"
                  />
                  Survey No.75/A Gagan Pahad, Rajendra Nagar Municipality Ranga
                  Reddy District, Telangana.
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="/assets/images/aboutus/call-icon.svg"
                    className="h-3.5 mr-1.5"
                  />
                  +91 1800 309 8822
                </p>
                <p className="flex items-center">
                  <img
                    alt="location-icon"
                    src="/assets/images/aboutus/mail-icon.svg"
                    className="h-3.5 mr-2"
                  />
                  sales@coltec-global.com
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F1F1F1]">
        <div className="px-6 lg:px-8 lg:py-20 py-10 max-w-7xl mx-auto">
          <ContactForm />
        </div>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Connect;
