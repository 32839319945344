import React from "react";

const Blogs = () => {
  return (
    <>
      <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
        <div className="lg:text-center">
          <h3 className="md:text-[40px] text-[28px] font-normal leading-8 mb-8">Our Blogs</h3>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md border h-56">
        </div>
        <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md border h-56">
        </div>
        <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md border h-56">
        </div>
        <div className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-md border h-56">
        </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
