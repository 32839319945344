import React from 'react'
import Header from '../components/Header'
const Products = () => {
  return (
    <>
    <Header/>
    <div>Products</div>
    </>
  )
}

export default Products