import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    mobileNumber: '',
    country: '',
    city: '',
    productCategories: [],
    industry: '',
    accountCategory: '',
    message: '',
    privacyPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'privacyPolicy') {
        setFormData((prevData) => ({ ...prevData, privacyPolicy: checked }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          productCategories: checked
            ? [...prevData.productCategories, value]
            : prevData.productCategories.filter((item) => item !== value),
        }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);

    // Reset form fields
    setFormData({
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      mobileNumber: '',
      country: '',
      city: '',
      productCategories: [],
      industry: '',
      accountCategory: '',
      message: '',
      privacyPolicy: false,
    });
  };

  return (
    <>
      <div className="lg:text-center">
        <h3 className="md:text-[40px] text-[28px] font-normal mb-2">Contact Sales</h3>
        <p className="text-sm max-w-xl mx-auto">
          If you experience any technical challenges or require assistance
          following your purchase, feel free to connect with our technical
          support
        </p>
      </div>
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
          <div>
            <label htmlFor="firstName" className="block text-gray-700 font-normal mb-2 text-sm">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
              required
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-gray-700 font-normal mb-2 text-sm">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
              required
            />
          </div>
          <div>
            <label htmlFor="companyName" className="block text-gray-700 font-normal mb-2 text-sm">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-normal mb-2 text-sm">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
              required
            />
          </div>
          <div>
            <label htmlFor="mobileNumber" className="block text-gray-700 font-normal mb-2 text-sm">Mobile Number</label>
            <input
              type="tel"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
          <div>
            <label htmlFor="country" className="block text-gray-700 font-normal mb-2 text-sm">Country/Region</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
          <div>
            <label htmlFor="city" className="block text-gray-700 font-normal mb-2 text-sm">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
          <div className="md:col-span-2">
            <label className="block text-gray-700 font-normal mb-2 text-sm">Product Category</label>
            <div className="flex flex-wrap gap-4">
              {['Interactive Flat Panel', 'Commercial Display', 'All-in-one Touch Monitor','Room Scheduler','Kiosks','Tablet','Video Wall','Active LED'].map((category) => (
                <div key={category} className="flex items-center">
                  <input
                    type="checkbox"
                    id={category}
                    name="productCategories"
                    value={category}
                    checked={formData.productCategories.includes(category)}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  <label htmlFor={category} className="text-gray-700 text-sm">{category}</label>
                </div>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="industry" className="block text-gray-700 font-normal mb-2 text-sm">Industry</label>
            <input
              type="text"
              id="industry"
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
          <div>
            <label htmlFor="accountCategory" className="block text-gray-700 font-normal mb-2 text-sm">Account Category</label>
            <input
              type="text"
              id="accountCategory"
              name="accountCategory"
              value={formData.accountCategory}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
            />
          </div>
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 font-normal mb-2 text-sm">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-0"
          ></textarea>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="privacyPolicy"
            name="privacyPolicy"
            checked={formData.privacyPolicy}
            onChange={handleChange}
            className="mr-2"
            required
          />
          <label htmlFor="privacyPolicy" className="text-gray-700 text-sm">I agree to the Privacy Policy</label>
        </div>
        <button
          type="submit"
          className="mt-3 bg-blue-500 text-white px-6 py-2.5 rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default ContactForm;
