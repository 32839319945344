import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import CTA from "../../components/CTA";
import OurClients from "../../components/OurClients";
import CorporateModule from "../../components/CorporateSwiper";
import ScrollToTopButton from "../../components/ScrollTop";
import Blogs from "../../components/Blogs";
import SEO from "../../components/SEO";
const cslides = [
  {
    heading: "Writing Board",
    description:
      "Our cutting-edge writing board offers unparalleled innovation with its seamless, endless surface. Designed for modern boardrooms, it enhances interaction, fosters creativity, and supports dynamic presentation methods, making meetings more engaging and effective.",
    image: "/assets/images/corporate/whiteboard.jpg",
  },
  {
    heading: "Presentation",
    description: `Deliver impactful corporate presentations with our state-of-the-art technology. Enhance your meetings with seamless integration, clear visuals, and interactive features designed to engage your audience and convey your message effectively.
Share content from any device wirelessly. Multi-share upto 4 devices simultaneously.`,
    image: "/assets/images/corporate/presentation.jpg",
  },
  {
    heading: "Share & Compare",
    description: `Effortlessly share and compare ideas, documents, and data with our intuitive tools. Enhance collaboration, streamline discussions, and improve decision-making in any setting with our user-friendly features.`,
    image: "/assets/images/corporate/share-compare.jpg",
  },
  // Add more slides as needed
];
const conslides = [
  {
    heading: "Teams",
    description:
      "Optimize your office meetings with our advanced tools for team collaboration. Facilitate seamless communication, share documents in real-time, and enhance productivity, ensuring every team meeting is efficient and effective.",
    image: "/assets/images/corporate/office-teams-meet.jpg",
  },
  {
    heading: "Zoom",
    description: `Enhance your Zoom meetings with our integrated solutions. Experience seamless connectivity, clear audio and video, and interactive features that make every meeting productive and engaging. Perfect for team collaboration, training sessions, and virtual events.`,
    image: "/assets/images/corporate/zoom-meet.jpg",
  },
  {
    heading: "Whiteboarding in Video Conferencing",
    description: `Enhance your video conferences with our whiteboarding feature. Collaborate in real-time, visualize ideas, and brainstorm effectively with interactive whiteboards. Perfect for team discussions, training sessions, and creative meetings, making remote collaboration seamless and productive.`,
    image: "/assets/images/corporate/whiteboarding-vc-call.jpg",
  },
  // Add more slides as needed
];
const tslides = [
  {
    heading: "Presentation",
    description:
      "Deliver powerful presentations with our advanced technology. Engage your audience with stunning visuals, interactive features, and seamless transitions. Perfect for business meetings, educational settings, and conferences, ensuring your message is clear, impactful, and memorable.",
    image: "/assets/images/corporate/training-presentation.jpg",
  },
  {
    heading: "Whiteboarding - Collab",
    description: `Enhance collaboration with our whiteboarding tools. Work together in real-time, share ideas visually, and brainstorm seamlessly. Ideal for team meetings, creative sessions, and problem-solving, our whiteboarding feature fosters effective and dynamic collaboration.`,
    image: "/assets/images/corporate/training-whiteboard.jpg",
  },
  {
    heading: "Video Conference",
    description: `Elevate your training sessions with our video conference technology. Deliver high-quality, interactive training to remote teams, ensuring clear communication, real-time feedback, and an engaging learning experience. Enhance productivity and learning outcomes with our reliable and user-friendly platform.`,
    image: "/assets/images/corporate/training-vc.jpg",
  },
  // Add more slides as needed
];
const Corporate = () => {
  return (
    <>
      <SEO
        title="Corporate Solutions - Collaborate | Conference & Train Effortlesslys | Coltec Global"
        description="Enhance Team’s Productivity with COLTEC’s State-of-the-art Interactive Visual Technology Solutions. Designed for modern meeting &training spaces."
        keywords="Collaborate, Conference & Train Effortlessly, Whiteboarding - Collab, Whiteboarding in Video Conferencing, Share & Compare"
        ogTitle="Corporate Solutions - Collaborate | Conference & Train Effortlesslys | Coltec Global"
        ogDescription="Enhance Team’s Productivity with COLTEC’s State-of-the-art Interactive Visual Technology Solutions. Designed for modern meeting &training spaces."
        ogImage="/assets/images/coltec-logo-black.svg"
        ogUrl="https://coltec-global.com/solutions/corporate"
        canonicalUrl="https://coltec-global.com/solutions/corporate"
      />
      <div className="bg-gray-900">
        <Header />
        <Banner
          imgSrc="/assets/images/corporate/banner-img.jpg"
          altText="banner-img"
          bannerHeight="md:h-dvh h-[60vh]"
          headerText="Collaborate | Conference & Train Effortlessly"
          opacityClasses="opacity-layer absolute inset-0 w-full h-full bg-neutral-liner z-0"
          paragraphText="Enhance Team’s Productivity with COLTEC’s State-of-the-art Interactive Visual Technology Solutions. Designed for modern meeting &training spaces."
          positionClasses="absolute md:bottom-64 bottom-20 left-0 lg:left-32"
          headerTextClasses="text-[29px] md:text-4xl max-w-xl text-white font-medium leading-10"
          paragraphTextClasses="text-base text-white max-w-lg mt-4"
        />
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Collaboration
          </h2>
          <CorporateModule slides={cslides} />
        </div>
      </div>
      <div className="bg-[#DADADA]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Conferencing
          </h2>
          <CorporateModule slides={conslides} />
        </div>
      </div>
      <div className="bg-[#E4E4E4]">
        <div className="px-6 lg:px-8 lg:py-14 py-10 max-w-7xl mx-auto">
          <h2 className="md:text-4xl text-[28px] font-normal mb-4 lg:text-center">
            Training
          </h2>
          <CorporateModule slides={tslides} />
        </div>
      </div>
      <div>
        <Blogs />
      </div>
      <div className="bg-gray-100">
        <OurClients />
      </div>
      <CTA />
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default Corporate;
