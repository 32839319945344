import React, { useEffect } from "react";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Education from "./pages/solutions/Education";
import Corporate from "./pages/solutions/Corporate";
import AboutUs from "./pages/AboutUs";
import Connect from "./pages/Connect";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
const App = () => {
  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/solutions/education",
      element: <Education />,
    },
    {
      path: "/solutions/corporate",
      element: <Corporate />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/connect",
      element: <Connect />,
    },
  ]);
  return (
    <>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </>
  );
};

export default App;
