import { Link } from "react-router-dom";

const CTA = () => {
    return (
      <div className="bg-[#30A0E3]">
        <div className="px-6 py-10 sm:px-6 lg:py-14 lg:px-8 max-w-7xl mx-auto">
          <div className="flex md:flex-row flex-col justify-start items-start md:justify-between md:items-center">
            <h2 className="lg:text-[34px] text-[26px] font-medium leading-[1.3] text-white md:max-w-3xl">
            Get the most advanced Visual Interactive Technology Solutions
            </h2>
            <div className="flex items-center justify-center gap-x-6 mt-8 md:mt-0">
              <Link
                to="/connect"
                className="flex items-center rounded-md bg-white lg:px-6 px-6 py-2 text-sm font-medium text-[#0A78B9] shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Send Enquiry <img alt="right-arrow" src="/assets/images/blue-arrow.svg" className="h-2 pl-2"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default CTA;